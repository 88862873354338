<div class="container">
  <div class="faild-page pl-20 pr-20">
    <img src="assets/images/faild.png">
    <div class="title">Отказ в бронировании услуги</div>
    <div class="lable">
      оплата будет возвращена на Ваш счёт<br>
      в течении нескольких минут
    </div>
    <div class="backurl" [routerLink]="['/']">
      Попробуйте забронировать еще раз
    </div>
    <div class="massage">
      Для перехода в Личный Кабинет<br>
      нажмите <img src="assets/images/arrow-back.svg"> вверху экрана
    </div>
  </div>
</div>