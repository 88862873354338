import { ActivatedRouteSnapshot, CanActivateFn, PreloadAllModules, Router, RouterModule, RouterStateSnapshot, Routes, UrlTree } from '@angular/router';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { inject } from '@angular/core';
import { AuthService, PROJECT_TYPE } from './core/services/auth.service';
import { SuccessComponent } from './components/success/success.component';
import { FaildComponent } from './components/faild/faild.component';
import { HttpClient } from '@angular/common/http';

const canActivateQueryParams: CanActivateFn = (route: ActivatedRouteSnapshot,  state: RouterStateSnapshot) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const queryParams = Object.keys(route.queryParams);
  if (authService.getToken()){
    
    if (queryParams.length && queryParams[0] === 'guid') {
      return authService.auth(route.queryParams).then((res) => {
        if (!!res) {
          router.navigate([`/`]);
        }
        return true
      });
    } else {
      return true;
    }
    
  }
  // ?guid=70d81264-008d-4b59-8415-b5233d184279&clientId=70d81264-008d-4b59-8415-b5233d184279&successTenantUrl=partner-ui.travelpublic.ru&failTenantUrl=partner-ui.travelpublic.ru
  if (queryParams.length && queryParams[0] === 'guid') {
    console.log(route.queryParams)
    return authService.auth(route.queryParams).then((res) => {
      return res
    });
  } else {
    // const http = inject(HttpClient);
    // let token = http.get(`/v1/settings/authorize?clientId=321312&successTenantUrl=https://travelb2b-ui.dev.vkomandirovke.online&failTenantUrl=https://travelb2b-ui.dev.vkomandirovke.online&SessionTimeoutUrl=https://travelb2b-ui.dev.vkomandirovke.online&type=air`,  {responseType: 'text',headers: {
    //   'Api-Key': `2b48ee9c-3818-4d51-8dba-074a90098a71`,
    // }}).toPromise().then(() => {
    //   // 
    // });
    router.navigate(['/error']);
    return false;
  }
};

const canActivatePaymentParams: CanActivateFn = (route: ActivatedRouteSnapshot,  state: RouterStateSnapshot) => {
  const router = inject(Router);
  if (!!route.queryParams['paymentStatus']) {
      return true;
    } else {
      router.navigate(['/air-search']);
      return false;
    }
};

const canActivateServiceType: CanActivateFn = (route: ActivatedRouteSnapshot,  state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (!authService?.tokenDetails) {
    router.navigate([`/`]);
    return false;
  }
  if (route.data['type'] === authService.tokenDetails?.serviceType) {
    return true;
  } else {
    router.navigate([`/${authService.tokenDetails?.serviceType}-search`]);
    return false;
  }
}

export const routes: Routes = [
  { path: 'error', component: ErrorPageComponent },
  { path: 'payment-success', canActivate: [canActivatePaymentParams], component: SuccessComponent },
  { path: 'payment-faild', canActivate: [canActivatePaymentParams], component: FaildComponent }, 
  {
    path: '',
    canActivate: [canActivateQueryParams],
    data: {
      type: PROJECT_TYPE.HOTEL 
    },
    children: [
      { path: '', redirectTo: 'hotel-search', pathMatch: 'full' },
      {
        path: 'hotel-search',
        loadChildren: () => import('./hotel-module/routes').then(m => m.hotelRoutes),
      },
    ],
    canActivateChild: [canActivateServiceType],
  },
  {
    path: '',
    data: {
      type: PROJECT_TYPE.AIR 
    },
    canActivate: [canActivateQueryParams],
    children: [
      { path: '', redirectTo: 'air-search', pathMatch: 'full' },
      {
        path: 'air-search',
        loadChildren: () => import('./air-module/air.module').then(m => m.AirModule),
      },
    ],
    canActivateChild: [canActivateServiceType],
  },
];

export const appRouter = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload',
  paramsInheritanceStrategy: 'always',
  scrollPositionRestoration: 'enabled',
});